/*
  500 Page, 
  when there is no page according to given route
*/

import React from 'react';

// Antd
import {Button, Row, Col} from 'antd';

// Style
import './style.scss'

// Localization
import LOCALIZATION from '../../../services/LocalizationService';

function Page500({
  isTable,
  refresh
}) {
  return (
    <div className={ !!isTable? 'page-404-table': 'page-404' }>

          <div className="card-white">
            <Row>

              <Col span={24}>
                <div className="content-404">
                <h2>500</h2>
                  <h4>{ LOCALIZATION.INTERNAL_SERVER_ERROR }</h4>
                  <p>{ LOCALIZATION.INTERNAL_SERVER_ERROR_MESSAGE }</p>
                  { 
                    !!refresh?
                      <Button
                        size="large"
                        type="primary"
                        onClick={ refresh }
                      >
                        {LOCALIZATION.REFRESH}
                      </Button>:
                      <p>
                        <a 
                          className="back404" 
                          href="/">
                            <i className="cicon-angle-right1"></i>
                              { LOCALIZATION.GO_TO_HOME }
                        </a>
                      </p> 
                  }
                </div>
              </Col>
            </Row>
        </div>
     
    </div>
  );
}
export default Page500;