// Service
import service from '../../services/Api/Service';
import StorageService from '../../services/StorageService';

// Constatns
import { USER_ACTIONS } from '../../constants/ActionKeys';
import { API_URLS } from '../../constants/ApiUrl';

// Helpers
import { isNewJobScreenAvailable } from "../../helpers/PermissionHelper";

// Dummy
// import PERMISSIONS from '../../constants/PermissionsMatrix';

// General
import { request, success, failure } from '.';

function login(loginModel) {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.LOGIN_REQUEST));

      // API Calling
      const response = await service.postService(
        API_URLS.AUTHENTICATE.LOGIN,
        loginModel,
        {},
        true
      );

      // Save Access Token
      const accessToken = response?.data?.access || null;
      StorageService.instance.setAccessToken(accessToken);

      // Save Refresh Token
      const refreshToken = response?.data?.refresh || null;
      StorageService.instance.setRefreshToken(refreshToken);

      // Save User Info
      StorageService.instance.setUserInfo(response?.data?.user || {});

      dispatch(success(USER_ACTIONS.LOGIN_SUCCESS, response));

      return response;
      
    } catch (error) {
      dispatch(failure(USER_ACTIONS.LOGIN_FAILURE));
      throw error;
    }
  };
}

function getUserPermissions() {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.LOGIN_REQUEST));

      // API Calling
      const response = await service.getService(
        API_URLS.AUTHENTICATE.USER_PERMISSIONS
      );

      // Save Permissions
      const permissions = response || [];
      StorageService.instance.setPermissions(permissions);

      // set Is New Job
      const flag = isNewJobScreenAvailable(permissions)? 0: 1;
      StorageService.instance.setIsReplacement(flag);

      dispatch(success(USER_ACTIONS.LOGIN_SUCCESS, response));

      return { flag, response };
      
    } catch (error) {
      dispatch(failure(USER_ACTIONS.LOGIN_FAILURE));
      throw error;
    }
  };
}

function logout() {
  return async (dispatch) => {
    try {
      dispatch(request(USER_ACTIONS.LOGOUT_REQUEST));
      await StorageService.instance.deleteLoginData();
      dispatch(success(USER_ACTIONS.LOGOUT_SUCCESS));
    } catch (error) {
      dispatch(failure(USER_ACTIONS.LOGOUT_FAILURE, error));
      throw error;
    }
  };
}

export {
  login,
  getUserPermissions,
  logout
};
