/*
    Permission Helpers
*/

import { ACTIONS, ENTITIES } from "../constants/Permissions";

// Services
import StorageService from '../services/StorageService';

/* General */

// View
export function getViewAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.VIEW);
}

// Add
export function getAddAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.ADD);
}

// Edit
export function getEditAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.EDIT);
}

// Delete
export function getDeleteAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.DELETE);
}

// Copy
export function getCopyAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.COPY);
}

// Total
export function getTotalAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.TOTAL);
}

// Import
export function getImportAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.IMPORT);
}

// Export
export function getExportAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.EXPORT);
}

// Contractor Report
export function getContractorReportAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.CONTRACTOR_REPORT);
}

// Financial Report
export function getFinancialReportAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.FINANCIAL_REPORT);
}

// Job Type Editable
export function getJobTypeEditable(permissions){
  return !!permissions?.includes(ACTIONS.JOB_TYPE_EDITABLE);
}

// Worker Status Editable
export function getWorkerStatusEditable(permissions){
  return !!permissions?.includes(ACTIONS.WORKER_STATUS_EDITABLE);
}

// Job Status Show
export function getJobStatusShow(permissions){
  return !!permissions?.includes(ACTIONS.JOB_STATUS_SHOW);
}

// Job Status Editable
export function getJobStatusEditable(permissions){
  return !!permissions?.includes(ACTIONS.JOB_STATUS_EDITABLE);
}

// Can see company Names
export function canSeeCompanyNames(permissions){
  return !!permissions?.includes(ACTIONS.CAN_SEE_COMPANY_NAMES);
}

// Can download
export function canDownload(permissions){
  return !!permissions?.includes(ACTIONS.DOWNLOAD);
}

export function canSeeRmc(permissions){
  return !!permissions?.includes(ACTIONS.CAN_SEE_RMC);
}

export function canChangeRmc(permissions){
  return !!permissions?.includes(ACTIONS.CAN_CHANGE_RMC);
}``

// Show Replacement Job
export function showReplacementJob(){
  const permissionList = StorageService.instance.getPermissions();
  return !!permissionList?.find(el => el.screen_id === ENTITIES.REPLACEMENT.JOB && el.action_id === ACTIONS.VIEW);
}

// Show Stages
export function showStages(){
  const permissionList = StorageService.instance.getPermissions();
  return !!permissionList?.find(el => el.screen_id === ENTITIES.REPLACEMENT.STAGE && el.action_id === ACTIONS.VIEW);
}

// Add Stage
export function addStage(){
  const permissionList = StorageService.instance.getPermissions();
  return !!permissionList?.find(el => el.screen_id === ENTITIES.REPLACEMENT.STAGE && el.action_id === ACTIONS.ADD);
}

export function isNewJobScreenAvailable(permissions){
  return !!permissions.filter(el => el.screen_id >= 1 && el.screen_id <= 100)?.length
}

export function isReplacementJobScreenAvailable(permissions){
  return !!permissions.filter(el => el.screen_id >= 101 && el.screen_id <= 200)?.length;
}

// On Hold Count Permission
export function getOnHoldCountAccessRight(permissions){
  return !!permissions?.includes(ACTIONS.ON_HOLD_COUNT);
}

export function getJobContractorEditable(permissions) {
  return !!permissions?.includes(ACTIONS.CAN_CHANGE_ASSIGNED_CONTRACTOR);
}

export function getJobContractorShow(permissions) {
  return !!permissions?.includes(ACTIONS.CAN_SEE_ASSIGNED_CONTRACTOR);
}

export function canSetJobStatusCompleteOrOnHold(permissions) {
  return !!permissions?.includes(ACTIONS.CAN_SET_TASK_STATUS_COMPLETE_ONHOLD);
}