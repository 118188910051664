export const EMAIL_PATTERN = /^(?=.*[^a-zA-Z0-9])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/;

export const SPACE_VALIDATIOR = '^\\S+[a-zA-Z0-9\\S#$%=+&-_\\s]*$'; 

export const SPECIAL_CHARACTERS_VALIDATIOR = /^(?=.*[^a-zA-Z0-9])/;

export const SPECIAL_CHARACTER_VALIDATION_EXCEPT_CURLYBRACES_FULLSTOP =/^[\dA-Z\s\.]*$/;

export const POSITIVE_WHOLE_NUMBER_NO_DECIMAL= /^(0|[1-9]\d*)$/;

export const POSITIVE_WHOLE_NUMBER_WITH_DECIMAL=/^[+]?\d*\.?\d+$/;

export const TEXT_NUMBER_VALIDATOR = /^[a-zA-Z0-9]+$/;

export const DIGITS_VALIDATIOR = /(?=.*[0-9])/;

export const CAPITAl_LETTER_VALIDATIOR = /(?=.*[A-Z])/;

export const SMALL_LETTER_VALIDATIOR = /(?=.*[a-z])/;

export const RANGE_VALIDATIOR = /^.{8,15}$/;

export const CHARACTER_LIMIT = /^[0-9]{0,999999999999}$/;
export const CODE_LIMIT = /^(?=.{1,10}$).*/;
// export const PHONE_PATTERN = /^[\+][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const PHONE_PATTERN = /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/;