/*
  Input Text
*/

import React from 'react';

// Antd
import { Input, Form } from 'antd';

// Localization
import LOCALIZATION from '../../../../services/LocalizationService';

import { DIGITS_VALIDATIOR } from '../../../../constants/Patterns';

function Number({ 
  name, label, placeholder, 
  readOnly, defaultValue, 
  onChange, disabled, required,
  onBlur, validator, validateTrigger,
  maxLength, notwrapInForm, noRule
}) {

  // Rules
  let rules = [
    { required: required, message: LOCALIZATION.REQUIRED },
    {
      pattern: DIGITS_VALIDATIOR,
      message: LOCALIZATION.DIGITS_VALIDATION
    }
  ];

  // Custom Validations
  !!validator && rules.push(validator);

  // Input Number
  const InputNumber = (
    <Input 
      placeholder={ placeholder || label } 
      onChange={ onChange || null } 
      defaultValue={ defaultValue }
      disabled={ disabled }
      onBlur={ onBlur }
      maxLength={ maxLength }
      readOnly={ readOnly }
      type="number"
    />
  );

  return(
    !!notwrapInForm?
      <>
        { InputNumber }
      </>:
      <Form.Item
        name={ name }
        label={ label }
        validateTrigger={ validateTrigger || 'onBlur' }
        rules={ noRule? [] : rules }
      >
        { InputNumber }
      </Form.Item>

  );
}

export default Number;