export const SECTIONS = {
  JOBS: 'jobs',
  SAFETY_FORM: 'safety-form',
  AUDIT_LOG: 'audit-log',
  REPORT: 'report',
  CONTRACTOR: 'contractor',
  WORKER: 'worker',
  PROJECT: 'project',
  STAGES: 'stages',
  ASCON: 'ascon'
};