export default {
    SUCCESS: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404,
    RESOURCE_CREATED: 201,
    SERVER_ERROR: 500,
    UNAUTHORIZE: 401,
    FORBIDDEN: 403,

    ACCESS_TOKEN: "ACCESS_TOKEN",
};
