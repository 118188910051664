/*
  Application Login Page Base Layout
*/

import React, { useEffect } from 'react';

// Redux
import {
  useDispatch,
} from 'react-redux';

//Components
import Loading from '../Loading';
import Error from '../Common/Alerts/Error';

// Antd
import { Row, Col } from 'antd';

// Actions
import { logout } from '../../store/actions/AuthAction';

// Style
import './style.scss';

function AuthenticationBase({
  loading,
  children,
  error
}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout())
  }, [dispatch]);

  return (
    <div className="login-wrapper">
      {
        loading &&
        // true &&
        <Loading />
      }
      
      <Row>  
        <Col md={12} xs={0}>
          <figure className="login-pic">
            <img src={"../assets/images/wa-logo-white.png"} alt="banner" />
          </figure>
        </Col>

        <Col md={12} xs={24}>
          <div className="form-row">       
            <Row className="login-form-wrapper">
              {/*Authentication Forms with Heading*/}              
              <Col md={24} xs={24}>

                {/*Error Message*/}
                <Error
                  show={!!error}
                  message={error}
                />


                {children}

              </Col>
            </Row>
          </div>
        </Col>

      </Row>
    </div>
  );
}

export default AuthenticationBase;