import { SECTIONS } from './MenuSections';

const { JOBS, AUDIT_LOG, REPORT, CONTRACTOR, WORKER, PROJECT, STAGES, ASCON, SAFETY_FORM } = SECTIONS;

const URL = {
  JOBS: `/${ JOBS }`, // New Job Listing,
  EDIT_JOB: `/${ JOBS }/:id`, // New Job Listing,
  EDIT_JOB_REPLACEMENT: `/${ PROJECT }/${ JOBS }/:id`, // Replacement Job Edit,
  EDIT_JOB_REPLACEMENT_ASCON: `/${ ASCON }/${ JOBS }/:id`, // Replacement Job Edit,
  SAFETY_FORM: `/${ SAFETY_FORM }/:id`, // Print Take Five
  AUDIT_LOG: `/${ AUDIT_LOG }`, // Audit Log,
  REPORT: `/${ REPORT }`, // Report,

  PROJECT: `/${ PROJECT }`, // Project,
  ADD_PROJECT: `/${ PROJECT }/add`, // Add Project,
  EDIT_PROJECT: `/${ PROJECT }/:id`, // Edit Project,


  STAGES: `/${ STAGES }`, // Stages,
  ASCON: `/${ ASCON }`, // Ascon,
  
  CONTRACTOR: `/${ CONTRACTOR }`, // Contractor,
  ADD_CONTRACTOR: `/${ CONTRACTOR }/add`, // Add Contractor,
  EDIT_CONTRACTOR: `/${ CONTRACTOR }/:id`, // Edit Contractor,

  
  WORKER: `/${ WORKER }`, // Worker,
  ADD_WORKER: `/${ WORKER }/add`, // Add Worker,
  EDIT_WORKER: `/${ WORKER }/:id`, // Edit Worker,


  PROJECT_DETAIL:`/${PROJECT}/detail/:id`, //Get project Details
  ADD_PROJECT_STAGE:`/${PROJECT}/:project_id/${STAGES}/add`, //Add  Project STAGE
  PROJECT_STAGE_DETAIL:`/${PROJECT}/:project_id/${STAGES}/:id`, //Get project stage detail
  EDIT_PROJECT_STAGE:`/${PROJECT}/:project_id/${STAGES}/edit/:id`, //Edit  Project STAGE

  ADD_STAGE_JOB:`/${PROJECT}/:project_id/${STAGES}/:id/add-job`, //Add job in stage detail

  AUTH: {
    LOGIN: '/login', // Login
    RESET_PASSWORD:'/resetPassword',// reset Password
    CONFIRM_PASSWORD:"/ConfirmPassword/:uid/:token" // Confirm Password
  },
  ERROR: {
    PAGE404: '/page404',
    PAGE500: '/page500',
  },
  
};

export default URL;