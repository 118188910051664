/* eslint-disable react/jsx-props-no-spreading */
/*
    Component to show only private routes
*/

import React from 'react';
import { Route, Redirect, Navigate } from 'react-router-dom';

// Application URL
import APP_URL from '../../constants/ApplicationUrls';

// Service
import StorageService from '../../services/StorageService';

const PrivateRoute = ({ element: Element, children }) => {
  const isAuthenticated = StorageService.instance.getAccessToken();
  if (!isAuthenticated) {
    return <Navigate to={{ pathname: APP_URL.AUTH.LOGIN }} />
  }
  else {
    return Element
  }
}

export default PrivateRoute