export const LANGUAGE = {
  EN: 1,
};

export const API_TIMEOUT = 90000;

export const CONTACT_SUPPORT = "mailto:info@partnername.com";

export const NOTIFICATION_DURATION = 4;

export const LOCAL_STORAGE_KEYS = {
  ACCESS_TOKEN: "WA_UTILS_ACCESS_TOKEN",
  REFRESH_TOKEN: "WA_UTILS_REFRESH_TOKEN",
  USER_INFO: "WA_UTILS_USER_INFO",
  PERMISSIONS: "WA_UTILS_PERMISSIONS",
  IS_NEW_JOB: "WA_UTILS_IS_NEW_JOB",
};

export const LISTING_DATA = {
  FIRST_PAGE: 1,
  PAGE_SIZE: 100,
  PAGE_SIZE_INFO_BLOCK: 100,
  PAGE_SIZE_20: 20,
  PAGE_SIZE_MAX_SIZE: 9999,
};

export const IMAGES_EXTENSIONS = "jpg, png, jpeg";
export const FILES_EXTENSIONS = "xlsx, xls, pdf, docx, doc, txt";
export const FILE_UPLOAD_TYPES =
  ".jpg, .jpeg, .png, .pdf, .xlsx, .docx, .EML, .eml";

export const MAX_FILE_SIZE = 20;

export const SORT = {
  ASC: "ascend",
  DESC: "descend",
};

export const SEARCH_TYPING_INTERVAL = 700;

export const DATE_FORMAT = "dd/MM/yyyy";

export const TIME_FORMAT = "HH:mm";

export const JOB_PANELS = {
  JOB_DETAILS: 1,
  TAKE_FIVE: 2,
  ACTIVITIES_PERFORMED: 3,
  METER_DETAILS: 4,
  ATTACHMENTS: 5,
};

export const ROLES = {
  CONTRACTOR: "contractor",
  WORKER: "worker",
  ADMIN:"admin,replacement_admin",
  NON_FINANCIAL_ADMIN:"non_financial_super_user"
};

export const EXCLUDE_METER_PANEL = ["SNF", "SPY", "SPU", "SNR","SNB"];
