// /*
//   Error Alert
// */

import React from 'react';

// Antd
import { Alert } from 'antd';

// Style
import './style.scss';

const Error = ({ show, message, closable, onClose }) => {
  const close = closable === undefined || closable === null ? true : closable;
  return (
    show &&
    <ul>
      {typeof message === 'string' ? (
        <li>
          <Alert
            message={message}
            type="error"
            closable={close}
            onClose={onClose}
            closeText={close ? <i className="icon-close"></i> : null}
          />
        </li>
      ) : Array.isArray(message) && (
        message.map((msg, index) => (
          <li key={index}>
            <Alert
              message={msg}
              type="error"
              closable={close}
              onClose={onClose}
              closeText={close ? <i className="icon-close"></i> : null}
            />
          </li>
        ))
      )}
    </ul>
  );
};

export default Error