// List of available actions in whole application
export const ACTIONS = {
  ADD: 1,
  EDIT: 2,
  DELETE: 3,
  VIEW: 4,
  ON_HOLD_COUNT: 5,
  IMPORT: 6,
  EXPORT: 7,
  COMPLETE_JOB_WITHOUT_TAKE5: 8,
  COPY: 9,
  CONTRACTOR_REPORT: 10,
  FINANCIAL_REPORT: 11,
  TOTAL: 12,
  JOB_TYPE_EDITABLE: 13,
  WORKER_STATUS_EDITABLE: 14,
  JOB_STATUS_SHOW: 15,
  JOB_STATUS_EDITABLE: 16,
  CAN_SEE_COMPANY_NAMES: 17,
  DOWNLOAD: 18,
  CAN_SEE_RMC: 19,
  CAN_CHANGE_RMC: 20,
  CAN_SEE_ASSIGNED_CONTRACTOR: 21,
  CAN_CHANGE_ASSIGNED_CONTRACTOR: 22,
  CAN_APPROVE_ADMIN_STATUS:23,
  CAN_APPROVE_CONTACTOR_STATUS:24,
  CAN_SET_TASK_STATUS_COMPLETE_ONHOLD:25

};

// Application Screen Ids
export const ENTITIES = {
  NEW: {
    AUDIT_LOG: 1,
    CONTRACTOR: 2,
    WORKER: 3,
    JOB: 4,
    REPORT: 5,
    ASCON: 6,
  },
  REPLACEMENT: {
    PROJECT: 101,
    STAGE: 102,
    JOB: 103,
    REPORT: 104,
    AUDIT_LOG: 105,
    CONTRACTOR: 106,
    WORKER: 107,
    ASCON: 108,
  },
};
