/*
  Application Login Page
*/

import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

// Antd
import { Form, Button } from "antd";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Components
import AuthenticationBase from "..";

// Helper
import {
  successNotification,
  errorNotification,
} from "../../../helpers/Notification";

// Form Elements
import { Text, Password } from "../../Common/FormElements";

// Actions
import { login, getUserPermissions } from "../../../store/actions/AuthAction";

// Constants
import APP_URL from "../../../constants/ApplicationUrls";
import STATUS_CODES from "../../../constants/StatusCodes";

// Localization
import LOCALIZATION from "../../../services/LocalizationService";
import LocalizationService from "../../../services/LocalizationService";

function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  let formRef = React.createRef();

  // Redux States
  const loading = useSelector((state) => state?.Auth?.loading);

  // useEffect(() => {
  //   props?.location?.state && setError(props.location.state)
  // }, [props?.location?.state]);

  // On Submit
  const onSubmit = (data) => {
    dispatch(login(data)).then(
      () => {
        getPermissions();
      },
      (e) => {
        const message = e?.response?.data?.detail
          ? e?.response?.data?.detail
          : e?.response?.status === STATUS_CODES.NOT_FOUND
          ? LOCALIZATION.INVALID_EMAIL_OR_PASSWORD
          : LOCALIZATION.NETWORK_ERROR;

        errorNotification(message);
      }
    );
  };

  const getPermissions = () => {
    dispatch(getUserPermissions()).then(
      ({ flag, response }) => {
        if (!!response?.length) {
          successNotification(LOCALIZATION.LOGIN_SUCCESSFULLY);

          if (!!flag) {
            // Replacement Case
            navigate(APP_URL.PROJECT);
          } else {
            // New Case
            navigate(APP_URL.JOBS);
          }

          window.location.reload();
        } else {
          errorNotification(LOCALIZATION.NO_PERMISSIONS);
        }
      },
      () => {
        errorNotification(LOCALIZATION.NETWORK_ERROR);
      }
    );
  };

  return (
    <AuthenticationBase loading={loading}>
      <div className="app-logo">
        <h1>Login</h1>
      </div>

      <Form form={form} ref={formRef} onFinish={onSubmit} layout="vertical">
        <div className="form-group">
          <Text
            label={LOCALIZATION.EMAIL_ADDRESS}
            className="form-control"
            name="email"
            required={true}
            placeholder=" "
          />
        </div>

        <div className="form-group">
          <Password
            label={LOCALIZATION.PASSWORD}
            className="form-control"
            name="password"
            required={true}
            minLength={1}
            placeholder=" "
          />
        </div>

        <Form.Item>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            className="mt-3"
          >
            {LOCALIZATION.SIGN_IN}
          </Button>
        </Form.Item>
      </Form>
      <Button
        onClick={() => navigate(APP_URL.AUTH.RESET_PASSWORD)}
        type="text primary"
      >
        {LOCALIZATION.RESET_PASSWORD}?
      </Button>
    </AuthenticationBase>
  );
}

export default Login;
